@charset "utf-8";

@import url("https://fonts.googleapis.com/css2?family=Lato&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&amp;display=swap");

$brand-pink: #FF1B8D;
$brand-blue: #1BB3FF;

$family-sans-serif: "Lato";

$primary: $brand-pink;
$info: $brand-blue;

@import "../node_modules/bulma/bulma.sass";

html, body, #root {
  height: 100%;
  margin: 0;
  overflow-y: hidden;
}

img.emoji {
   height: 1em;
   width: 1em;
   margin: 0 .05em 0 .1em;
   vertical-align: -0.1em;
}

@media only screen and (max-width: 767px) {
    .header {
        flex-direction: column;
    }
}
